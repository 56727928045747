import React from "react";
import styled from "styled-components";

import Loader from "../components/Loader";

import API_URL from "../utils/api_url";

class Feedback extends React.Component {
    state = {
        toggle: false,
        loading: false,
        submitted: false,
        object: '',
        message: '',
        email: '',
        error: ''
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
      }
    
    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    handleClickOutside = (e) => {
        if (this.wrapperRef && !this.wrapperRef.contains(e.target)) {
            this.closeForm();
        }
    }

    setWrapperRef = (node) => {
        this.wrapperRef = node;
    }

    openForm = () => {
        this.setState({ toggle: true, loading: false, submitted: false });
    }

    closeForm = () => {
        this.setState({ 
            toggle: false, 
            loading: false, 
            submitted: false,
            error: '' 
        });
    }

    handleChange = (e) => {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    handleSubmit = () => {
        const { message, email, object } = this.state;
        this.setState({ loading: true, error: '' });

        if (!message || !email || !object) {
            return this.setState({ error: 'All fields are required!', loading: false });
        } 

        fetch(`${ API_URL }/feedback`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ message, email, object })
        })
            .then(resp => resp.json())
            .then((data) => {
                if (!data.success) {
                    return this.setState({ error: data.message, loading: false })
                }
                this.setState({ message: '', email: '', object: '', submitted: true, loading: false });
            })
            .catch(err => {
                this.setState({ 
                    loading: false, 
                    error: 'Something isn\'t working, I\'ll look into it as fast as I can. In the meantime you can email me at: saqovish@gmail.com' 
                });
            })
    }

    render() {
        const { toggle, email, message, loading, submitted, error } = this.state;

        return <Container>
            { !toggle && <ToggleButton onClick={ this.openForm } >Feedback/Contribute</ToggleButton> }
            { toggle && <Wrapper ref={ this.setWrapperRef }>
                { !submitted ? <><FormHeader>
                    <CloseButton onClick={ this.closeForm } />
                    <Title>Feedback/Contribute</Title>
                    <p>You want to give me some feedback or you didn't find your favorite resource, please contact me :D</p>
                </FormHeader>
                <FormWrapper>
                { !loading ? <>
                    { error && <ErrorWrapper>{ error }</ErrorWrapper> }
                    <Input 
                        name="email"
                        value={ email }
                        onChange={ this.handleChange }
                        type="email" 
                        placeholder="Email..." />
                    <Textarea 
                        name="message"
                        value={ message }
                        onChange={ this.handleChange }
                        placeholder="Links to the resources or feedback..." 
                        rows="4" />
                    <Radio onChange={ this.handleChange }>
                        <input 
                            type="radio" 
                            value="contribution"
                            name="object" />Contribution
                        <input 
                            type="radio" 
                            value="feedback"
                            name="object" />Feedback<br />
                    </Radio>
                    <Button onClick={ this.handleSubmit }>Submit</Button> </> : <Loader /> }
                </FormWrapper></> : <SuccessWrapper>
                    <SuccessHeader>
                        <CloseButton onClick={ this.closeForm } />
                        <p>Thank you!</p>
                        <img src={ require('../images/smiley.svg') } alt="thank you smiley" />
                        <p>Your feedback or contribution has been sent successfully.</p>
                    </SuccessHeader>
                </SuccessWrapper>} 
            </Wrapper>}
        </Container>
    }
}

const Container = styled.div`
    position: fixed;
    right: 0;
    bottom: 0;
`;

const CloseButton = styled.div`
    text-align: right;
    position: relative;
    cursor: pointer;

    &::after {
        width: 14px;
        height: 2px;
        background: white;
        content: '';
        display: block;
        position: absolute;
        top: 0;
        transform: rotate(-45deg);
        right: 0;
    }

    &::before {
        width: 14px;
        height: 2px;
        background: white;
        content: '';
        display: block;
        position: absolute;
        transform: rotate(45deg);
        right: 0;
    }
`;

const ErrorWrapper = styled.p`
    color: #ff4545;
    font-weight: bold;
    font-size: 14px;
`;

const SuccessWrapper = styled.div`
    text-align: center;
`;

const SuccessHeader = styled.div`
    background: #8852e8;
    padding: 16px;
    color: white;
    font-size: 14px;
    border-radius: 5px;

    img {
        width: 90px;
    }
`;

const Wrapper = styled.div`
    position: absolute;
    bottom: 100%;
    margin-bottom: 15px;
    right: 12px;
    width: 280px;
    border-radius: 5px;
    background: #f2f2f2;
    box-shadow: #a6a6a68c 0 0 23px;
`;

const FormHeader = styled.div`
    background: #8852e8;
    padding: 16px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    color: white;
    font-size: 14px;
`;

const FormWrapper = styled.div`
    padding: 12px 18px 24px 18px;
    display: flex;
    justify-content: center;
    flex-direction: column;
`;

const Title = styled.p`
    font-size: 18px;
    font-weight: bold;
    margin: 0;
    margin-top: 24px;
`;

const Input = styled.input`
    font-size: 14px;
    padding: 8px;
    box-sizing: border-box;
    border-radius: 5px;
    border: 1px solid #a9a9a9;
    outline: none;
    margin-bottom: 12px;
    width: 100%;
`;

const Textarea = styled.textarea`
    font-size: 14px;
    padding: 8px;
    border-radius: 2px;
    border: 1px solid #a9a9a9;
    outline: none;
    color: black;
    margin-bottom: 12px;
    width: 100%;
    resize: none;
    box-sizing: border-box;
    border-radius: 5px;
`;

const Radio = styled.div`
    font-weight: bold;
    font-size: 14px;
    color: #555;

    input:not(:first-child) {
        margin-left: 16px;
    }
`;

const ToggleButton = styled.button`
    font-size: 14px;
    font-weight: bold;
    padding: 6px 18px;
    background: #8852e8;
    border: none;
    border-top-left-radius: 5px;
    cursor: pointer;
    color: white;
    outline: none;
`;

const Button = styled.button`
    background: #8852e8;
    border: none;
    font-size: 16px;
    color: white;
    padding: 8px 24px;
    margin-top: 18px;
    border-radius: 3px;
    transition: 0.8s all cubic-bezier(0.2, 0.8, 0.2, 1);
    width: 100%;
    outline: none;

    &:hover {
        transform: translateY(-1px);
        cursor: pointer;
    }
`;

export default Feedback;