import React from "react"
import PropTypes from "prop-types"
import "../styles/index.css"
import Feedback from "./Feedback"
import styled from "styled-components";
import ReactGA from "react-ga";

const analyticsClickedTwitterLink = () => {
  ReactGA.event({
    category: 'Twitter',
    action: `Clicked my twitter link`
  })
}

const Layout = ({ children }) => {
  return (
    <>
        <main>{children}</main>
        <Feedback />
        <Footer>
          © copyright { new Date().getFullYear() } | by <strong><a 
            href="https://twitter.com/saqovish" 
            target="__blank" 
            rel="noopener noreferrer"
            onClick={ () => analyticsClickedTwitterLink() }>saqovish</a></strong>
        </Footer>
    </>
  )
}

const Footer = styled.div`
  font-size: 12px;
  box-sizing: border-box;
  padding: 12px 20px;
  color: grey;
  font-weight: bold;
  margin-top: -12px;
  position: absolute;
  bottom: 0;
  z-index: -5;

  a {
    color: #8852e8;
    font-weight: bold;
    text-transform: capitalize;
  }
`;

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
