import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";

const Header = () => {
    return <header style={{ padding: "24px" }}>
        <Logo style={{ textAlign: "center" }}><Link to="/">DEVKIT.XYZ</Link></Logo>
    </header>
}

const Logo = styled.p`
    font-weight: bold;
    margin: 0;

    a {
        color: black;
    }
`;

export default Header;